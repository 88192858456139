import React from 'react'
import styled from 'styled-components'

// Utils
import { getPathname } from '../../../utils'

// Hooks
import useLayoutCtx from '../../../hooks/context/useLayoutCtx'

// Components
import { Link } from 'gatsby'
import Image from 'gatsby-image'

const Wrapper = styled(Link)`
  text-align: center;
  padding: ${mobileVW(12)} ${mobileVW(12)} ${mobileVW(12)} ${mobileVW(11)};
  margin: 0 ${mobileVW(16)};
  background-color: ${props => props.background};
  border-radius: ${mobileVW(20)};
  height: ${mobileVW(195)};
  display: flex;
  border: ${props =>
    props.background !== 'white'
      ? `solid ${mobileVW(2)} rgba(0,0,0,0)`
      : `solid ${mobileVW(2)} ${colors.beigeUltraLight}`};

  @media (min-width: ${desktopBreakpoint}) {
    border: ${props =>
      props.background !== 'white'
        ? 'solid 2px rgba(0,0,0,0)'
        : `solid 2px ${colors.beigeUltraLight}`};
    border-radius: 20px;
    height: 246px;
    min-width: 463px;
    width: 463px;
    max-width: 500px;
    padding: 15px;
    margin: 0 20px;
    .gatsby-image-wrapper {
      transition: all 0.5s ease-in-out;
    }
    &:hover {
      .gatsby-image-wrapper {
        transform: scale(1.1);
      }
      background-color: ${colors.beigeUltraLight};
      border: ${props =>
        props.background !== '#EEE3DA'
          ? `solid 2px white`
          : 'solid 2px rgba(0,0,0,0)'};
    }
  }
`

const ImageWrapper = styled.div`
  overflow: hidden;
  border-radius: ${mobileVW(12)};
  @media (min-width: ${desktopBreakpoint}) {
    border-radius: 12px;
  }
  .gatsby-image-wrapper {
    width: ${mobileVW(134)};
    height: ${mobileVW(171)};
    img {
      height: 100%;
      width: 100%;
    }
    @media (min-width: ${desktopBreakpoint}) {
      width: 169px;
      height: 216px;
    }
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${mobileVW(20)};
  text-align: left;
  position: relative;
  flex-basis: 50%;
  justify-content: center;
  @media (min-width: ${desktopBreakpoint}) {
    margin-left: 24px;
  }
`

const Label = styled.div`
  font-family: ${font.larsseit};
  font-size: ${mobileVW(14)};
  padding: ${mobileVW(5)} ${mobileVW(10)} ${mobileVW(4)};
  background: ${props => (props.color ? props.color : 'white')};
  border-radius: ${mobileVW(100)};
  width: fit-content;
  width: max-content;
  line-height: ${mobileVW(16.8)};
  position: absolute;
  bottom: 0;
  @media (min-width: ${desktopBreakpoint}) {
    padding: 5px 10px 4px;
    border-radius: 100px;
    font-size: 16px;
    line-height: 1;
  }
`

const Title = styled.h3`
  font-family: ${font.ben};
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: ${props =>
    props.increaseFont ? `${mobileVW(31)}` : `${mobileVW(27)}`};
  font-size: ${props =>
    props.increaseFont ? `${mobileVW(28)}` : `${mobileVW(24)}`};
  padding-block-end: ${mobileVW(4)};
  font-weight: normal;
  color: ${props => (props.increaseFont ? `${colors.brownLight}` : 'black')};
  text-transform: lowercase;
  @media (min-width: ${desktopBreakpoint}) {
    line-height: ${props => (props.increaseFont ? `39px` : `31px`)};
    font-size: ${props => (props.increaseFont ? `36px` : `28px`)};
    padding-block-end: 4px;
  }
`

const Subtitle = styled.p`
  font-family: ${font.larsseit};
  max-height: 100%;
  text-overflow: ellipsis;
  color: ${props =>
    props.increaseFont ? `${colors.brownLight}` : `${colors.brownDarkest}`};
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: ${mobileVW(18)};
  font-size: ${props =>
    props.increaseFont ? `${mobileVW(14)}` : `${mobileVW(16)}`};
  line-height: ${props =>
    props.increaseFont ? `${mobileVW(18)}` : `${mobileVW(22)}`};
  margin-top: ${mobileVW(4)};
  @media (min-width: ${desktopBreakpoint}) {
    font-size: 16px;
    line-height: 22px;
    margin-top: 6px;
  }
`

import { mobileVW, font, desktopBreakpoint, colors } from '../../../styles'

const Card = ({ card }) => {
  const { node_locale } = useLayoutCtx()

  const {
    title,
    backgroundColor,
    content,
    thumbnail,
    largerFont,
    description,
    label,
    externalLink,
  } = card

  return (
    <>
      {externalLink ? (
        <Wrapper
          as='a'
          background={backgroundColor ? backgroundColor : 'white'}
          href={externalLink}
          target='_blank'
          className='card_container'
        >
          <ImageWrapper>
            <Image
              fluid={
                thumbnail?.fluid ||
                content.image?.fluid ||
                content.imageThumbnail?.fluid
              }
            />
          </ImageWrapper>
          <TextWrapper>
            <Title increaseFont={largerFont}>
              {title ? title : content.title}
            </Title>
            <Subtitle increaseFont={largerFont}>
              {description ||
                content?.productDescriptionShort ||
                content?.preview}
            </Subtitle>
            {content?.label || label ? (
              <Label color={label ? label.colorCode : content.label.colorCode}>
                {label?.title || content?.label.title}
              </Label>
            ) : null}
          </TextWrapper>
        </Wrapper>
      ) : (
        <Wrapper
          background={backgroundColor ? backgroundColor : 'white'}
          to={getPathname(node_locale, content?.slug)}
          className='card_container'
        >
          <ImageWrapper>
            <Image
              fluid={
                thumbnail?.fluid ||
                content.image?.fluid ||
                content.imageThumbnail?.fluid
              }
            />
          </ImageWrapper>
          <TextWrapper>
            <Title increaseFont={largerFont}>
              {title ? title : content.title}
            </Title>
            <Subtitle>
              {description ||
                content?.productDescriptionShort ||
                content?.preview}
            </Subtitle>
            {content?.label || label ? (
              <Label color={label ? label.colorCode : content.label.colorCode}>
                {label?.title || content?.label.title}
              </Label>
            ) : null}
          </TextWrapper>
        </Wrapper>
      )}
    </>
  )
}

export default Card
