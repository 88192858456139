import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import Card from '../uiComponents/card'
import ArticleProductCard from '../uiComponents/articleProductCard'
import useIsMobile from '../../../hooks/useIsMobile'
import Button from '../uiComponents/button'
import BoxedContainer from '../boxedContainer'
import RichText from '../RichText'

const Wrapper = styled.div`
  text-align: center;
  position: relative;
  display: ${props => (props.hide ? 'none' : 'block')};
  color: ${colors.brownDarkest};
  padding: ${props =>
    props.paddingSize == 'xs'
      ? `${mobileVW(48)} 0`
      : props.paddingSize == 'l'
      ? `${mobileVW(109)} 0 ${mobileVW(110)}`
      : `${mobileVW(80)} 0`};
  background: ${props =>
    props.gradient
      ? `linear-gradient(to bottom, ${props.colors[0]} 0%, ${props.colors[1]} 100%);`
      : `${props.colors}`};
  h2 {
    color: ${props =>
      props.gradient
        ? 'white'
        : props.textColor
        ? `${props.textColor}`
        : `${colors.brownDarkest}`};
    font-size: ${props =>
      props.gradient ? `${mobileVW(48)}` : `${mobileVW(36)}`};
    @media (min-width: ${desktopBreakpoint}) {
      font-size: 48px;
      line-height: 52px;
    }
  }

  .card_container {
    border-color: ${props => (props.gradient ? 'rgba(0,0,0,0)' : null)};
  }

  &.section_block_collapsed {
    padding-bottom: 0;

    .button_container {
      margin: 0;
    }
  }

  &.section_block_grid + .section_block_grid {
    padding-top: 0;
  }

  @media (min-width: ${desktopBreakpoint}) {
    min-height: 340px;
    position: relative;
    padding: ${props =>
      props.paddingSize == 'xs'
        ? `48px 0`
        : props.paddingSize == 'm'
        ? `112px 0`
        : `72px 0 112px`};
    width: ${props => (props.fullWidth ? '100%' : '50%')};
    display: ${props => (props.fullWidth ? 'block' : 'inline-block')};
  }
`

const Title = styled.h2`
  font-family: ${font.ben};
  font-size: ${mobileVW(36)};
  line-height: 1.3;
  text-align: center;
  margin: 0 ${mobileVW(32)} ${mobileVW(32)};
  text-transform: lowercase;
  font-weight: normal;
  @media (min-width: ${desktopBreakpoint}) {
    font-size: 48px;
    line-height: 52px;
    margin: 0 24px 24px;
  }
`

const Subtitle = styled.h3`
  font-family: ${font.larsseit};
  font-size: ${mobileVW(18)};
  line-height: ${mobileVW(28)};
  text-align: center;
  margin: 0 ${mobileVW(32)};
  @media (min-width: ${desktopBreakpoint}) {
    font-size: 20px;
    line-height: 32px;
    margin: 0 auto 24px;
    max-width: ${props => (props.fullWidth ? `800px` : `470px`)};
  }
`

const CardsWrapper = styled.div`
  a:not(:last-of-type) {
    margin-bottom: ${mobileVW(16)};
  }
  margin-bottom: ${mobileVW(45)};
  @media (min-width: ${desktopBreakpoint}) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    a:not(:last-of-type) {
      margin-bottom: auto;
    }
  }
`

const VerticalCardsWrapper = styled.div`
  display: grid;
  align-items: flex-start;
  grid-template-columns: repeat(${props => props.columns}, 1fr);
  grid-column-gap: ${mobileVW(16)};
  grid-row-gap: ${mobileVW(48)};
  padding: 0 ${mobileVW(16)};
  margin-top: ${mobileVW(32)};
  margin-bottom: ${mobileVW(48)};

  .product_card {
    padding: 0;
  }

  @media (min-width: ${desktopBreakpoint}) {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(
      ${props => props.columns},
      minmax(auto, 262px)
    );
    grid-column-gap: 40px;
    grid-row-gap: 110px;
    justify-content: center;
    margin-top: 70px;
    margin-bottom: 48px;

    & + .button_container {
      margin-top: 0;
    }
  }
`

const ButtonWrapper = styled.div`
  margin-top: ${mobileVW(24)};
  display: flex;
  justify-content: center;
  padding: ${props => (props.buttonCount == '2' ? `${mobileVW(16)}` : '0')};

  .button_wrapper {
    margin: ${props => (props.buttonCount == '2' ? `0` : 'initial')};
    min-width: ${props => (props.buttonCount == '2' ? `50%` : 'auto')};
    @media (min-width: ${desktopBreakpoint}) {
      min-width: ${props => (props.buttonCount == '2' ? `200px` : 'auto')};
    }
    &:first-of-type {
      margin-right: ${mobileVW(10)};
      @media (min-width: ${desktopBreakpoint}) {
        margin-right: 10px;
      }
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    margin-top: 24px;
    position: absolute;
    left: 0;
    width: 100%;
    padding: 0;
  }
`

const ObserverAnchorTop = styled.span`
  position: absolute;
  top: -1.5vh;
  left: 0;
  height: 0px;
  width: 0px;
  pointer-events: none;
  z-index: -1;

  @media (min-width: ${desktopBreakpoint}) {
    top: -12.5vh;
  }
`

const ObserverAnchorBottom = styled.span`
  position: absolute;
  bottom: 5vh;
  left: 0;
  height: 0px;
  width: 0px;
  pointer-events: none;
  z-index: -1;

  @media (min-width: ${desktopBreakpoint}) {
    bottom: 0;
  }
`

const LongTextWrapper = styled.div`
  padding: 40px 36px;
  font-family: ${font.larsseit};
  text-align: center;
  font-size: 16px;
  line-height: 22px;

  p:not(:last-of-type) {
    margin-bottom: 80px;
    @media (min-width: ${desktopBreakpoint}) {
      margin-bottom: 43px;
    }
  }

  h2 {
    color: ${colors.brownDark};
    font-family: ${font.ben};
    font-size: 36px;
    line-height: 39px;
    max-width: 250px;
    margin: 0 auto 29px;
    @media (min-width: ${desktopBreakpoint}) {
      max-width: none;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    max-width: 650px;
    margin: auto;
  }
`

import { mobileVW, font, colors, desktopBreakpoint } from '../../../styles'

const SectionBlock = ({ content, changeColor }) => {
  const {
    title,
    description,
    fullWidth,
    backgroundColors,
    cards,
    cardsVertical,
    button,
    button2,
    limitVisibleArticles,
    longDescription,
    makeHeaderMenu,
  } = content
  const isMobile = useIsMobile()

  const columnsMobile = limitVisibleArticles ? limitVisibleArticles[0] : 2
  const columnsDesktop =
    limitVisibleArticles && limitVisibleArticles[1] >= 4
      ? 4
      : limitVisibleArticles
      ? limitVisibleArticles[1]
      : 3

  const [isCollapsed, setIsCollapsed] = useState(false)

  const [columns, setColumns] = useState(
    isMobile ? columnsMobile : columnsDesktop,
  )
  const [limit, setLimit] = useState(
    isMobile
      ? columnsMobile
      : limitVisibleArticles
      ? limitVisibleArticles[1]
      : columnsDesktop,
  )

  const showAll = e => {
    e.preventDefault()
    e.target.parentElement.style.display = 'none'

    setIsCollapsed(true)
    setLimit(null)
  }

  useEffect(() => {
    setColumns(isMobile ? columnsMobile : columnsDesktop)

    if (!isCollapsed)
      setLimit(
        isMobile
          ? columnsMobile
          : limitVisibleArticles
          ? limitVisibleArticles[1]
          : columnsDesktop,
      )
  }, [isMobile])

  return (
    <>
      <Wrapper
        paddingSize={
          !title && !description
            ? 'xs'
            : backgroundColors
            ? 'l'
            : fullWidth
            ? 'm'
            : 's'
        }
        colors={backgroundColors ? backgroundColors : 'white'}
        gradient={backgroundColors && backgroundColors[1] ? true : false}
        fullWidth={fullWidth}
        textColor={changeColor ? `${colors.brownDark}` : null}
        className={`section_block ${backgroundColors &&
          'has-background'} ${isCollapsed &&
          'section_block_collapsed'} ${cardsVertical && 'section_block_grid'}`}
      >
        {title && makeHeaderMenu && (
          <ObserverAnchorTop
            id={title
              .toLowerCase()
              .split(' ')
              .join('_')}
            data-anchor='top'
            data-section={title
              .toLowerCase()
              .split(' ')
              .join('_')}
          ></ObserverAnchorTop>
        )}
        {title && makeHeaderMenu && (
          <ObserverAnchorBottom
            data-anchor='bottom'
            data-section={title
              .toLowerCase()
              .split(' ')
              .join('_')}
          ></ObserverAnchorBottom>
        )}
        <BoxedContainer>
          {content.title ? <Title>{title}</Title> : null}
          {description ? (
            <Subtitle fullWidth={fullWidth}>{description}</Subtitle>
          ) : null}
          {cards ? (
            <CardsWrapper>
              {cards?.map((card, i) => {
                return <Card card={card} key={i} />
              })}
            </CardsWrapper>
          ) : null}
          {cardsVertical ? (
            <VerticalCardsWrapper
              columns={columns}
              className='vertical_cards_wrapper'
            >
              {cardsVertical?.map((card, i) =>
                limit && i <= limit - 1 ? (
                  <ArticleProductCard content={card} key={i} />
                ) : !limit ? (
                  <ArticleProductCard content={card} key={i} />
                ) : null,
              )}
            </VerticalCardsWrapper>
          ) : null}
          {button ? (
            <ButtonWrapper buttonCount={button2 ? '2' : '1'}>
              <Button
                slug={button.link && button.link[0].slug}
                ctaType={backgroundColors ? 'secondary' : 'terciary'}
                text={button.title}
              />
              {button2 && (
                <Button
                  slug={button2.link && button2.link[0].slug}
                  ctaType={backgroundColors ? 'secondary' : 'terciary'}
                  text={button2.title}
                />
              )}
            </ButtonWrapper>
          ) : null}
          {limitVisibleArticles &&
          limit != cardsVertical.length &&
          limit <= cardsVertical.length ? (
            <ButtonWrapper className='button_container'>
              <Button
                cta={e => showAll(e)}
                ctaType={backgroundColors ? 'secondary' : 'terciary'}
                text={'load more articles'}
              />
            </ButtonWrapper>
          ) : null}
          {longDescription && (
            <LongTextWrapper>
              <RichText json={longDescription.json} />
            </LongTextWrapper>
          )}
        </BoxedContainer>
      </Wrapper>
    </>
  )
}

export default SectionBlock
