import React from 'react'
import styled from 'styled-components'

import { desktopBreakpoint } from '../../styles'

const Box = styled.div`
  @media (min-width: ${desktopBreakpoint}) {
    margin: auto;
    max-width: 1280px;
  }
`

const BoxedContainer = ({ children, fullWidth, className }) => {
  return <Box className={className}>{children}</Box>
}

export default BoxedContainer
