import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import { desktopBreakpoint, headerHeight, colors, font } from '../styles'

// Utils
import { getPathname } from '../utils'

// Hooks
import useLayoutCtx from '../hooks/context/useLayoutCtx'

// Components
import Image from 'gatsby-image'
import RichText from '../components/shared/RichText'
import ArrowFlat from '../components/graphics/ArrowFlat'
import SEO from '../components/shared/SEO'
import SectionBlock from '../components/shared/modules/sectionBlock'

const Container = styled.div`
  .expert_container + .section_block {
    padding-top: 0;
    h3 {
      font-size: 16px;
      line-height: 22px;
      &:first-child {
        font-size: 18px;
        line-height: 21px;
        @media (min-width: ${desktopBreakpoint}) {
          font-size: 24px;
          line-height: 27px;
        }
      }
    }

    h2 {
      margin-bottom: 16px;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    .section_block {
      .vertical_cards_wrapper {
        grid-row-gap: 60px;
      }
    }
  }
`

const StyledExpert = styled.section`
  padding: ${headerHeight.mobile}px 0 54px;
  font-family: ${font.larsseit};

  @media (min-width: ${desktopBreakpoint}) {
    padding: ${headerHeight.desktop}px 0 101px;
    max-width: 950px;
    margin: auto;
  }
`

const Header = styled.div`
  margin: 60px 16px 0;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: ${colors.brownLight};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 16px;
    margin: 103px auto 10px;
  }
`

const BackLink = styled(Link)`
  text-decoration: underline;
`

const ExpertWrapper = styled.div`
  border-radius: 12px;
  margin: 13px 16px 0;
  padding: 19px 23px 17px;
  background-color: rgba(238, 227, 218, 0.25);

  .gatsby-image-wrapper {
    border-radius: 12px;
    width: 297px;
    height: 297px;
    @media (min-width: ${desktopBreakpoint}) {
      width: 262px;
      height: 262px;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    background-color: none;
    display: flex;
    justify-content: space-between;
    background: none;
    padding: 0;
    margin: 0;

    .expert_detail {
      background-color: rgba(238, 227, 218, 0.25);
    }
  }
`

const Name = styled.h2`
  font-size: 36px;
  font-family: ${font.ben};
  margin-top: 25px;
  text-transform: lowercase;
  @media (min-width: ${desktopBreakpoint}) {
    margin-top: 0;
  }
`

const JobFunction = styled.div`
  font-size: 16px;
  margin-top: 10px;
  color: ${colors.brownLight};
  text-transform: uppercase;
`

const Details = styled.div`
  margin-top: 14px;
  font-size: 16px;
  line-height: 22px;
`

const Col = styled.div`
  @media (min-width: ${desktopBreakpoint}) {
    &:nth-child(2) {
      border-radius: 12px;
      padding: 27px 30px 20px 27px;
      margin-left: 13px;
    }
  }
`
const ArrowWrapper = styled.div`
  display: flex;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-left: 16px;
  align-items: center;
  justify-content: center;
  background-color: rgba(238, 227, 218, 0.25);

  a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    transform: ${({ left }) => (left ? 'rotate(90deg)' : 'rotate(-90deg)')};
    path {
      stroke: ${colors.brownLight};
    }
  }
`
const ArrowLink = styled(Link)``

const Expert = ({
  data: {
    contentfulExpert: {
      name,
      slug: expertSlug,
      details,
      jobFunction,
      image,
      seoTitle,
      seoDescription,
      sectionBlock,
    },
    allContentfulModularPage: {
      edges: [
        {
          node: { slug, contentBlocks },
        },
      ],
    },
  },
}) => {
  const { node_locale } = useLayoutCtx()
  const experts = contentBlocks[0].block.people
  const ExpertIndex = experts.findIndex(expert => expert.name === name)
  const AmountExperts = experts.length - 1

  return (
    <Container>
      <SEO title={seoTitle} description={seoDescription} slug={expertSlug} />
      <StyledExpert className='expert_container'>
        <Header>
          <BackLink to={getPathname(node_locale, slug)}>
            Terug naar overzicht
          </BackLink>
          <div style={{ display: 'flex' }}>
            {ExpertIndex !== -1 && (
              <>
                <ArrowWrapper left>
                  <ArrowLink
                    to={getPathname(
                      node_locale,
                      ExpertIndex !== 0
                        ? experts[ExpertIndex - 1].slug
                        : experts[AmountExperts].slug,
                    )}
                  >
                    <ArrowFlat />
                  </ArrowLink>
                </ArrowWrapper>
                <ArrowWrapper>
                  <ArrowLink
                    to={getPathname(
                      node_locale,
                      ExpertIndex < AmountExperts
                        ? experts[ExpertIndex + 1].slug
                        : experts[0].slug,
                    )}
                  >
                    <ArrowFlat />
                  </ArrowLink>
                </ArrowWrapper>
              </>
            )}
          </div>
        </Header>
        <ExpertWrapper>
          <Col>
            <Image fluid={image.fluid} alt={image.title} />
          </Col>
          <Col className={'expert_detail'}>
            <Name>{name}</Name>
            <JobFunction>{jobFunction}</JobFunction>
            <Details>
              <RichText json={details.json} />
            </Details>
          </Col>
        </ExpertWrapper>
      </StyledExpert>
      {sectionBlock && <SectionBlock content={sectionBlock} />}
    </Container>
  )
}

export const PageQuery = graphql`
  query Expert($id: String!, $node_locale: String!) {
    contentfulExpert(id: { eq: $id }) {
      name
      slug
      seoTitle
      seoDescription
      jobFunction
      image {
        title
        fluid(maxWidth: 300) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      details {
        json
      }
      sectionBlock {
        id
        name
        title
        description
        backgroundColors
        fullWidth
        limitVisibleArticles
        button {
          title
          link {
            ... on ContentfulModularPage {
              id
              name
              slug
            }
            ... on ContentfulCollectionPage {
              id
              name
              slug
            }
            ... on ContentfulTextPage {
              name
              slug
            }
          }
        }
        cards {
          backgroundColor
          title
          largerFont
          description
          thumbnail {
            fluid(maxWidth: 300) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          content {
            ... on ContentfulCollectionPage {
              id
              name
              slug
            }
          }
          externalLink
        }
        cardsVertical {
          ... on ContentfulArticle {
            id
            preview
            title
            slug
            image {
              fluid(maxWidth: 300) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            label {
              title
              colorCode
            }
            content {
              json
            }
          }
        }
      }
    }
    allContentfulModularPage(
      filter: {
        name: { eq: "Experts Overview - Modular" }
        node_locale: { eq: $node_locale }
      }
    ) {
      edges {
        node {
          slug
          contentBlocks {
            block {
              ... on ContentfulComponentGrid {
                title
                gridSubtitle {
                  json
                }
                includeCardSubtitle
                includeButton
                people {
                  slug
                  jobFunction
                  image {
                    fluid(maxWidth: 300) {
                      ...GatsbyContentfulFluid_withWebp
                    }
                  }
                  hoverImage {
                    fluid(maxWidth: 300) {
                      ...GatsbyContentfulFluid_withWebp
                    }
                  }
                  name
                  shortSubtitle
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Expert
